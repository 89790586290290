import * as React from "react"
import Layout from "../components/common/layout"
import { StaticImage } from "gatsby-plugin-image"
import HeroSection from "../components/hero-section"

import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube"
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook"
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram"
import ContactForm from "../components/kontakt/contactForm"

import {
  BGLinearGradient,
  Separator,
} from "../components/common/styledComponentsHelper"
export const Head = () => (
  <>
    <title>Kontakt | Fachhandwerk360</title>
    <meta name="description" content="Alles was ein Handwerker braucht" />
    <meta
      name="keywords"
      content="Kalkulation, Beratung, Digitalisierung, Handwerk, Software, kaufmännischer
      Begleiter Bauhandwerk"
    />
    <meta
      name="facebook-domain-verification"
      content="xooo742lkxtkqa24ish1wyf2ppfc4e"
    />
  </>
)

const Kontakt = () => (
  <Layout>
    <HeroSection
      desktopImg="kontakt_banner_desktop.png"
      mobileImg="kontakt_banner_mobile.png"
      headlineText="Kontakt"
      responsiveHeadline="Kontakt"
      subheadlineText="Wir beraten Sie gerne"
      captionText=""
    />
    <BGLinearGradient>
      <Separator />
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-xl-6">
            <ContactForm />
          </div>
          <div className="col-xl-6">
            <div className="w-sm-75 container text-light h5 fw-bold pt-5 pb-5 text-sm-start text-center">
              <StaticImage
                src="../images/contact/kontakt.jpg"
                alt="Kontakt"
                width={500}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
              />
              <p className="mt-5">Wir beraten Sie gerne</p>
              <StaticImage
                src="../images/FHW360_logo_light_lang.png"
                alt="Fachhandwerk360 Logo"
                width={500}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
              />
              <p className="pt-sm-0 pt-3">
                Rheinstraße 28
                <br />
                55432 Niederburg
                <br />
                Deutschland
              </p>
              <p>Tel.: 06744 9491087</p>
              <p className="fw-light h6 mt-5">Folgen Sie uns</p>
              <a
                href="https://www.youtube.com/channel/UCpN_meVFT0Ut6Cgu0myFrQw"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube size="3rem" className="me-3" color="white" />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100082946961422"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook size="3rem" className="me-3" color="white" />
              </a>
              <a
                href="https://www.instagram.com/fachhandwerk360/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram size="3rem" className="me-3" color="white" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </BGLinearGradient>
  </Layout>
)

export default Kontakt
